<template>  
	<button v-bind:class="[ classes ? classes : 'btn btn-secondary']">
		{{texto}} <span v-html="'&nbsp'"></span>    <i v-bind:class="[ icone ? icone : 'fa fa-check']"></i> 
	</button> 
</template>

<script>
	export default {  
		props:[
		'texto' , 'classes' , 'icone'
		],   
	} 
</script>

<style scoped>
.btn{
	margin-left: 5px;
}
</style>