<template>  
	<router-link  :to="url" exact    v-bind:class="[ classes ? classes : 'btn btn-secondary']">
		<i v-bind:class="[ icone ? icone : 'fa fa-reply']"></i> {{message}}
	</router-link>	
</template>

<script> 

	export default {

		props:[
		'url' , 'texto' , 'classes' , 'icone'
		], 

		computed: { 
			message: function () { 
				if(this.texto){
					return this.texto;
				}
				return 'Voltar'; 
			}
		},

	}

</script>

<style> 
</style>