

import jQuery from 'jquery';


window.alertProcessando = function() {
  jQuery('body').addClass('loading');
}
    
  
  
    
    window.alertProcessandoHide = function() {
      jQuery('body').removeClass('loading');
    }
    
    window.alertErro = function( titulo , texto = "", posicao = "center", funcao = function() {}) {
      window .iziToast.show({
          theme: 'dark',
          position: posicao,
          color: '#DD4B39',
          title: titulo,
          titleColor: '#fff',
          titleSize: '14',
          message: texto,
          messageColor: '#fff',
          timeout: 10000,
          icon: 'fa fa-ban',
          iconColor: '#fff',
          closeOnEscape: true,
          onClosed: funcao
      });
    }
    
    window.toastErro = function(titulo   , texto = "", funcao = function() {}) {
      window .alertErro(titulo, texto, 'topRight', funcao);
      // window .alertErro(titulo, texto, 'bottomRight', funcao);
    }
    
    
    window.alertNotificacao = function( titulo   , texto = "", posicao = "center", funcao = function() {}) {
      window .iziToast.show({
          theme: 'dark',
          position: posicao,
          color: '#ffc107',
          title: titulo,
          titleColor: '#fff',
          titleSize: '32',
          message: texto,
          messageColor: '#fff',
          timeout: false,
          icon: 'fa fa-ban',
          iconColor: '#fff',
          closeOnEscape: true,
          onClosing: funcao
      });
    }
    
    
    window.notificacao = function( titulo   , texto = "", funcao = function() {}) {
      // alertNotificacao(titulo, texto, 'topRight' );
      window.alertNotificacao(titulo, texto, 'topRight', funcao);
    }
    
    window.alertSucesso = function( titulo  , texto = "", posicao = "center", funcao = function() {} , timeout = 10000 ) {
      window .iziToast.show({
          theme: 'dark',
          timeout: timeout, 
          position: posicao,
          color: '#1F5688',
          title: titulo,
          titleColor: '#fff',
          titleSize: '14',
          message: texto,
          messageColor: '#fff',
          
          icon: 'fa fa-check',
          iconColor: '#fff',
          closeOnEscape: true,
          onClosed: funcao
      });
    }
    
    window.toastSucesso = function( titulo   , texto = "", funcao = function() {} ,  timeout = 10000   ) {
      window .alertSucesso(titulo, texto, 'topRight', funcao , timeout );
      // window .alertSucesso(titulo, texto, 'bottomRight', funcao , timeout );
    }
    
    window.alertConfimacao = function( titulo   , texto  , funcaoSIM  ) {
      window.iziToast.show({
          theme: 'dark',
          color: '#3C8DBC',
          titleColor: '#fff',
          messageColor: '#fff',
          timeout: false,
          icon: 'fa fa-question-circle-o',
          iconColor: '#fff',
          close: false,
          overlay: true,
          toastOnce: true,
          zindex: 999,
          title: titulo,
          message: texto,
          position: 'center',
          buttons: [
          
          ['<button>Sim</button>', function (instance   , toast   ) {
              instance.hide({
                  transitionOut: 'fadeOutUp', 
              }, toast);
              funcaoSIM();
          }],
          
          ['<button><b>Não</b></button>', function(instance   , toast  ) {
              instance.hide({
                  transitionOut: 'fadeOutUp', 
              }, toast ); 
          }, true]
    
          ],
          id: 'iziToastConfirmacao'
      });
    }
    