import { segurancaModel } from './index.model'; 
 

export const loginService = {
	login,
	logout,
	getUrl,
	tokenPortal,
};


const url = segurancaModel.getUrl();

const url2 = segurancaModel.getUrlProfile()  ;  


function getUrl(  ) { 
	return  window.http_api.defaults.baseURL + url; 
}
 

function login(token) { 
	let header = {'Authorization': 'Bearer ' +   token  };  
	return  new Promise((resolve, reject) => {
		window.http_api.post( url + '/login/token' , {} , {headers: header }  )
		.then( (response  ) => { 
			resolve( response.data.access_token );  
		})
		.catch((error ) => { 
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}

 

function tokenPortal(token) { 
	return  new Promise((resolve, reject) => {
		window.http_api.get( url2 + '/admin/login/' + token + '/jorjao' , {}   )
		.then( (response  ) => {  
			resolve( response.data );  
		})
		.catch((error ) => {  
			reject(error.response);
		}) 
	}); 
}



function logout() { 
	localStorage.removeItem('user');
	localStorage.clear( ); 
}
