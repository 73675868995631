<template>  
    <campo-custom :errors="form.errors.has(name)" :errors_texto="form.errors.get(name)">
        <label :for="name">{{label}}:</label>
	    <input :style="estilo" :required="required"  :readonly="readonly"  :disabled="disabled" :type="type" :id="name" :name="name" class="form-control" v-model="form[name]" v-bind:class="{ 'is-invalid': form.errors.has(name) }" v-bind:min=" min ? min : '' "  v-bind:max=" max ? max : '' " v-bind:step=" step ? step : '' "> 
    </campo-custom> 
</template>

<script> 
	export default { 
		props:[
            'name' , 
            'label',
            'type',
            'form',
            'estilo',
            'step',
            // 'readonly',
            //  required
        ],  
        

        computed: {
            
            required(){
                if (this.$attrs.hasOwnProperty('required')) {
                    if(this.$attrs.required === false){
                        return false;
                    }
                   return true;
                }
                return false;
            },
            
            disabled(){
                if (this.$attrs.hasOwnProperty('disabled')) {
                    if(this.$attrs.disabled === false){
                        return false;
                    }
                   return true;
                }
                return false;
            },

            readonly(){
                if (this.$attrs.hasOwnProperty('readonly')) {
                    if(this.$attrs.readonly === false){
                        return false;
                    }
                   return true;
                }
                return false;
            },
            
            min(){
                if (this.$attrs.hasOwnProperty('min')) { 
                   return this.$attrs.min;
                }
                return false;
            },
            
            max(){
                if (this.$attrs.hasOwnProperty('max')) {
                   return this.$attrs.max;
                }
                return false;
            },


        },


	} 
</script>

<style> 
</style>