<template>  
	<button-custom  classes="btn btn-success" texto="Salvar"   >  </button-custom> 
</template>

<script>
	export default {  

	} 
</script>

<style scoped>
 
</style>