import { assentamentoModel } from './index.model'; 
 

export const processoSeletivoService = { 
    getUrl,
    // MeusDadosDoRhPeloMeuRg,
	VerificarMinhaInscricao,
	MeusDados,
	VerificarProcessoSeletivoAberto,
	MeusDadosInscricao,
};


const url = assentamentoModel.getUrl() + '/processoseletivo' ;  



function getUrl(  ) { 
	return window.http_api.defaults.baseURL + url; 
}

 


function MeusDados(  ) { 
	return  new Promise((resolve, reject) => {
		window.http_api.get( url  + '/meusdados'   )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			reject(error.response);
		}) 
	}); 
}




// function MeusDadosDoRhPeloMeuRg(  ) { 
// 	return  new Promise((resolve, reject) => {
// 		window.http_api.get( url + '/dados/rh/rg'      )
// 		.then(response => {    
// 			resolve( response.data);  
// 		})
// 		.catch(error => {  
// 			reject(error.response);
// 		}) 
// 	}); 
// }





function VerificarProcessoSeletivoAberto(  ) { 
	return  new Promise((resolve, reject) => {
		window.http_api.get( url + '/inscricoes/abertas'      )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			reject(error.response);
		}) 
	}); 
}





function VerificarMinhaInscricao( curso ) { 
	return  new Promise((resolve, reject) => {
		window.http_api.get( url + '/minhaincricao/' + curso    )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			reject(error.response);
		}) 
	}); 
}





function MeusDadosInscricao( curso ) { 
	return  new Promise((resolve, reject) => {
		window.http_api.get( url + '/meusdados/incricao'     )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			reject(error.response);
		}) 
	}); 
}

