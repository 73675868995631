import { assentamentoModel } from './index.model'; 
 

export const situacaoService = {
	getModel,
    getUrl,
    getTodos,
};


const url = assentamentoModel.getUrl() + '/situacao' ;  



function getUrl(  ) { 
	return window.http_api.defaults.baseURL + url; 
}



function getModel( id  ) { 
	return  new Promise((resolve, reject) => {
		window.http_api.get( url + '/' + id    )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			reject(error.response);
		}) 
	}); 
}


 
function getTodos(search) {  
    if(search){
        return  new Promise((resolve, reject) => {
            window.http_api.get( url + '?search=' + search    )
            .then(response => {    
                resolve( response.data);  
            })
            .catch(error => {  
                reject(error.response);
            }) 
        }); 
    } 
    return  new Promise((resolve, reject) => {
		window.http_api.get( url )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			reject(error.response);
		}) 
	}); 
}

