<template>
    <div v-if="situacao"> 
        <div class="row">
            <div class="col-12">
                <h2 class="text-center" v-if="currentProcessoSeletivo"> PROCESSO SELETIVO {{ currentProcessoSeletivo.concurso_dsc.replace('_' , ' ') }} </h2>
            </div>
        </div> <br> 
        <div v-if="situacao.status === 'Inscrito' " class="text-center">
            <v-alert dense border="left" type="success" max-width="744" class="mx-auto">
                <h3 class="align-center">Situação: Inscrição Realizada!</h3>
            </v-alert>
            <a class="btn btn-primary " @click="dialog = true;"> Visualizar Comprovante</a>
        </div> 
        <div v-else> 
            <form method="patch" action="#" @submit.prevent="onSubmit" @keydown="form.errors.clear($event.target.name)">
                <custom-card>  
                    <v-row>
                        <v-col cols="12" sm="6" lg="4">
                            <input-simples type="text" name="nome" :form="form" label="Nome" :disabled="true" />
                        </v-col>
                        <v-col cols="12" sm="6" lg="2">
                            <input-simples type="text" name="cpf" :form="form" label="CPF" :disabled="true" />
                        </v-col>
                        <v-col cols="12" sm="4" lg="2">
                            <input-simples type="text" name="rg" :form="form" label="RG" :disabled="true" />
                        </v-col>
                        <v-col cols="12" sm="4" lg="2">
                            <input-simples type="text" name="num_funcional" :form="form" label="Nº Funcional" :disabled="true" />
                        </v-col>
                        <v-col cols="12" sm="4" lg="2">
                            <input-simples type="date" name="nascimento" :form="form" label="Data de nascimento" :disabled="true" />
                        </v-col>
                    </v-row> 
    
                    <v-row>
                        <v-col cols="12" sm="4" lg="3" xl="4">
                            <input-simples type="text" name="post_grad_dsc" :form="form" label="Post / Grad" :disabled="true" />
                        </v-col>
                        <v-col cols="12" sm="4" lg="3" xl="2">
                            <div class="form-group">
                                <label for="promocao">Última promoção:</label>
                                <input :required="true" :disabled="true" type="date" id="promocao" name="promocao" class="form-control" v-model="form.promocao" :class="{ 'is-invalid': form.errors.has('promocao') , 'input-invalid-promocao': dataPromocaoInvalida }">
                                <div class="invalid-feedback" v-if="errors" v-text="form.errors.get('promocao')"></div>
                                <div class="invalid-feedback-processo-seletivo" v-if="dataPromocaoInvalida">O militar ainda não possui 1 anos na graduação!</div>
                            </div>
                        </v-col>
                        <v-col cols="12" sm="4" lg="2" xl="2">
                            <input-simples type="text" name="promocao_bol_dsc" :form="form" label="Boletim promoção" :disabled="true" />
                        </v-col>
                        <v-col cols="12" sm="6" lg="2" xl="2">
                            <div class="form-group">
                                <label for="incorporacao">Incorporação:</label>
                                <input :required="true" :disabled="true" type="date" id="incorporacao" name="incorporacao" class="form-control" v-model="form.incorporacao" :class="{ 'is-invalid': form.errors.has('incorporacao') , 'input-invalid-incorporacao': dataIncorporacaoInvalida }">
                                <div class="invalid-feedback" v-if="errors" v-text="form.errors.get('incorporacao')"></div>
                                <div class="invalid-feedback-processo-seletivo" v-if="dataIncorporacaoInvalida">O militar ainda não possui 10 anos de Incorporação!</div>
                            </div>
                        </v-col>
                        <v-col cols="12" sm="6" lg="2" xl="2">
                            <input-simples type="text" name="ome_dsc" :form="form" label="Unidade" :disabled="true" />
                        </v-col>
                    </v-row> 
                    <v-row>
                        <v-col cols="12" sm="6" xl="4">
                            <input-simples type="text" name="saude_promocao_tipo_dsc" :form="form" label="Saúde" :disabled="true" />
                        </v-col>
                        <v-col cols="12" sm="6" xl="2">
                            <div class="form-group">
                                <label for="saude_promocao_validade">Validade / Saúde:</label>
                                <input :required="true" :disabled="true" type="date" id="saude_promocao_validade" name="saude_promocao_validade" class="form-control" v-model="form.saude_promocao_validade" :class="{ 'is-invalid': form.errors.has('saude_promocao_validade') , 'input-invalid-promocao': dataSaudeInvalida }">
                                <div class="invalid-feedback" v-if="errors" v-text="form.errors.get('saude_promocao_validade')"></div>
                                <div class="invalid-feedback-processo-seletivo" v-if="dataSaudeInvalida">O militar encontra-se com a validade de saúde vencida para fins de promoção!</div>
                            </div>
                        </v-col>
                        <v-col cols="12" sm="4" xl="2">
                            <input-simples type="text" name="saude_promocao_bol_dsc" :form="form" label="Boletim / Saúde" :disabled="true" />
                        </v-col>
                        <v-col cols="12" sm="4" xl="2">
                            <input-simples type="text" name="disciplina_promocao_dsc" :form="form" label="Comportamento" :disabled="true" />
                        </v-col> 
                        <v-col cols="12" sm="4" xl="2">
                            <input-simples type="text" name="disciplina_promocao_bol_dsc" :form="form" label="Boletim / Comportamento" :disabled="true" />
                        </v-col>
                    </v-row> 
                    <v-row>
                        <v-col cols="12" sm="8" lg="6" xl="4">
                            <input-simples type="text" name="usuario_email" :form="form" label="Email"  />
                        </v-col>
                        <v-col cols="12" sm="4" lg="3" xl="2">
                            <input-simples type="text" name="telefone" :form="form" label="Telefone" :required="true" />
                        </v-col>
                        <v-col cols="12" sm="4" lg="3" xl="2">
                            <crudFormElemento :errors="form.errors.has('atdp_inscricao_atend_especial')" :errors_texto="form.errors.get('atdp_inscricao_atend_especial')">
                                <label>Possui Necessidades Especiais?: </label>
                                <select2 v-model="form.atdp_inscricao_atend_especial" class="form-control col-9" id="atdp_inscricao_atend_especial" :errors="form.errors.has('atdp_inscricao_atend_especial')" :errors_texto="form.errors.get('atdp_inscricao_atend_especial')" v-on:input="form.errors.clear('atdp_inscricao_atend_especial')">
                                    <option value="2">Não</option>
                                    <option value="1">Sim</option>
                                </select2>
                            </crudFormElemento>
                        </v-col>
                        <v-col cols="12" sm="8" lg="12" xl="4" v-if="form.atdp_inscricao_atend_especial == 1">
                            <input-simples type="text" name="atdp_inscricao_atend_especial_dsc" :form="form" label="Especificar Nec. Especiais" />
                        </v-col>
                    </v-row> 
 
                    
                    <v-row> 
                        <v-col cols="12">
                            <label>DECLARAÇÃO DE SITUAÇÃO: </label> 
                            <br> 
                            <label>1: </label> 
                            <br>
                            <input type="radio" id="situacao_0" name="situacao" value="0" v-model="form.atdp_inscricao_situacao" required>&nbsp;&nbsp;
                            <label for="situacao_0">NÂO cumpro pena privativa de liberdade por sentença condenatória transitada em julgado ou por improbidade administrativa dolosa ou, suspensão condicional de pena (SURSIS).</label>
                            <br> 
                            <input type="radio" id="situacao_1" name="situacao" value="1" v-model="form.atdp_inscricao_situacao" required>&nbsp;&nbsp;
                            <label for="situacao_1">Cumpro pena privativa de liberdade por sentença condenatória transitada em julgado ou por improbidade administrativa dolosa ou, suspensão condicional de pena (SURSIS).</label> 
                        </v-col>
                        <v-col cols="12" v-if="form.atdp_inscricao_situacao == 1">
                            <input-simples type="text" name="atdp_inscricao_situacao_processo" :form="form" label="Especificar Nº Processo" required/>
                        </v-col>

                        <v-col cols="12"> 
                            <br>
                            <label>2: </label> 
                            <br>
                            <input type="radio" id="condicao_0" name="condicao" value="0" v-model="form.atdp_inscricao_condicao" required>&nbsp;&nbsp;
                            <label for="condicao_0">Não estou na condição de desertor, desaparecido, extraviado ou ausente, conforme legislação vigente.</label>
                            <br> 
                            <input type="radio" id="condicao_1" name="condicao" value="1" v-model="form.atdp_inscricao_condicao" required>&nbsp;&nbsp;
                            <label for="condicao_1">Estou na condição de desertor, desaparecido, extraviado ou ausente, conforme legislação vigente.</label><br> 
                        </v-col>  
                        <v-card-text  class="text-h6 text-danger"  >
                            Responsabilizo-me pelas informações prestadas neste documento e estou ciente que declarações inverídicas, poderão implicar a este signatário procedimento administrativo, civil e criminal.
                        </v-card-text> 
                    </v-row> 
                    
                    <template v-slot:footer v-if="!(this.dataIncorporacaoInvalida || this.dataPromocaoInvalida )"> 
                            <div class="card-footer text-right">   
                                <button  class="btn btn-primary">
                                    <b style="font-size:18px;">Realizar Inscrição </b> <span v-html="'&nbsp'"></span>    <i class=" fa fa-check "></i> 
                                </button>  
                            </div>
                    </template>
                    <template v-slot:footer v-else> 
                            <div class="card-footer  "> 
                                <v-card-text  class="text-h6 text-danger"  >
                                    Requisitos do art. 16 da LC nº 911/2019: <br>
                                    - Ser Cabo com no mínimo 01 (um) ano de interstício nesta graduação e 10 (dez) anos de efetivo serviço na respectiva Corporação, computados na forma da Lei nº 3.196/1978 e da LC nº 911/2019;
                                </v-card-text>
                                <v-card-text  class="text-h6 text-danger"  >
                                    Caso existam DADOS INCONSISTENTES verificados no SGPM, que estejam impedindo a realização da inscrição, deverá ser solucionado junto a P1 de sua Unidade ou diretamente na DRH. 
                                </v-card-text>
                            </div> 
                    </template>
                </custom-card>  
            </form>   
        </div> 
        <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition" scrollable > 
            <v-row> 
                <v-col cols="12"> 
                    <v-row style="margin-bottom:-5px">
                        <v-col cols="12"> 
                            <v-toolbar  dark dense> 
                                <v-btn   dark  @click="dialog = false" >
                                    <v-icon>mdi-close</v-icon> Fechar
                                </v-btn>  
                            </v-toolbar> 
                        </v-col>
                    </v-row> 
                    <iframe :src="inscricao" width="100%" height="94%"   style="border: none;"></iframe>  
                </v-col>
            </v-row> 
        </v-dialog>  
         
    </div>
    </template> 
    <script>
    import { mapGetters } from "vuex";
    import moment from "moment";
    
    import Form from "@/components/_core/formulario/Form";
    import { processoSeletivoService } from '@/service';
    
    export default {
    
        data() {
            return {  
                situacao: '', 
                meusDados: '', 
                meusDadosInscricao: '', 
                dialog: false,
                confirmaEnvio: false, 
                inscricao: '', 
                form: new Form({ 
                    nascimento: '',
                    nome: '',
                    rg: '',
                    num_funcional: '',
                    cpf: '',
                    situacao_cod: '',
                    situacao_dsc: '',
                    incorporacao: '',
                    post_grad_dsc: '',
                    ome_dsc: '',
                    ome_qdi: '',
                    usuario_email: '',
                    telefone: '-',
                    quadro_dsc: '', 
                    promocao: '',
                    promocao_bol_dsc: '', 
                    atdp_inscricao_condicao: '', 
                    atdp_inscricao_situacao: '', 
                    atdp_inscricao_situacao_processo: '',  
                    incorporacao_bol_dsc: '', 
                    disciplina_promocao_bol_dsc: '',
                    disciplina_promocao_dsc: '',
                    disciplina_promocao_data: '', 
                    saude_promocao_bol_dsc: '',
                    saude_promocao_data: '',
                    saude_promocao_tipo_cod: '',
                    saude_promocao_tipo_dsc: '',
                    saude_promocao_validade: '', 
                    atdp_inscricao_atend_especial: '2',
                    atdp_inscricao_atend_especial_dsc: '', 
                    is_valida_incorporacao: true,
                    is_valida_promocao: true,
                    is_valida_saude: true, 
                    concurso_cod: '',
                    concurso_diretriz: '',
                    concurso_dsc: '', 
                }),
    
            }
        },
    
        watch: {
     
            meusDadosInscricao: function(newDados, oldDados) { 
                this.form.nome = this.meusDadosInscricao.efetivo.nome;
                this.form.rg = this.meusDadosInscricao.efetivo.rg;
                this.form.num_funcional = this.meusDadosInscricao.efetivo.num_funcional;
                this.form.cpf = this.meusDadosInscricao.efetivo.cpf;
                this.form.situacao_cod = this.meusDadosInscricao.efetivo.situacao_cod;
                this.form.situacao_dsc = this.meusDadosInscricao.efetivo.situacao_dsc;
                this.form.incorporacao = this.meusDadosInscricao.efetivo.incorporacao;
                this.form.nascimento = this.meusDadosInscricao.efetivo.nascimento;
                this.form.quadro_dsc = this.meusDadosInscricao.efetivo.quadro_dsc; 
                this.form.promocao = this.meusDadosInscricao.efetivo.promocao;
                this.form.promocao_bol_dsc = this.meusDadosInscricao.efetivo.promocao_bol_dsc;  
                this.form.incorporacao_bol_dsc = this.meusDadosInscricao.efetivo.incorporacao_bol_cod != 1 ? this.meusDadosInscricao.efetivo.incorporacao_bol_dsc:''; 
                this.form.post_grad_dsc = this.meusDadosInscricao.efetivo.post_grad_dsc;
                this.form.ome_dsc = this.meusDadosInscricao.efetivo.ome_dsc;
                this.form.ome_qdi = this.meusDadosInscricao.efetivo.ome_qdi; 
                try {
                    this.form.disciplina_promocao_bol_dsc = this.meusDadosInscricao.disciplina.disciplina_promocao_bol_dsc;
                    this.form.disciplina_promocao_dsc = this.meusDadosInscricao.disciplina.disciplina_promocao_dsc;
                    this.form.disciplina_promocao_data = this.meusDadosInscricao.disciplina.disciplina_promocao_data;
                } catch (error) {
                    console.log('Error ao analisar a disciplina');
                }
     
                this.form.saude_promocao_bol_dsc = this.meusDadosInscricao.saude.saude_promocao_bol_dsc;
                this.form.saude_promocao_data = this.meusDadosInscricao.saude.saude_promocao_data;
                this.form.saude_promocao_tipo_cod = this.meusDadosInscricao.saude.saude_promocao_tipo_cod;
                this.form.saude_promocao_tipo_dsc = this.meusDadosInscricao.saude.saude_promocao_tipo_dsc;
                this.form.saude_promocao_validade = this.meusDadosInscricao.saude.saude_promocao_validade;
     
                // this.form.saude_promocao_validade ='2024-09-01' ;  //TODO: comentar
                // this.form.promocao = '2024-09-01'  ; //TODO: comentar
                // this.form.incorporacao = '2014-09-05'  ; //TODO: comentar
     
            }, 
    
            meusDados: function(newDados, oldDados) {
                this.form.usuario_email = this.meusDados.email_pmes;
                this.form.telefone = this.meusDados.telefone;
            },
     
            situacao: function(newDados, oldDados) {
                if (this.situacao.inscricao) {
                    this.inscricao = processoSeletivoService.getUrl() + '/incricao/' + this.situacao.inscricao.inscricao_cod + '/pdf' + '?token=' + this.token;
                }
            }
     
        },
     
        created() {
            if (!this.currentProcessoSeletivo) {
                this.$router.push('/');
                return;
            }
            this.VerificarMinhaInscricao();
            this.buscarMeusDados();
        },
    
    
        methods: { 
            VerificarMinhaInscricao() {
                alertProcessando();
                processoSeletivoService.VerificarMinhaInscricao(this.currentProcessoSeletivo.concurso_cod)
                .then(response => {
                    this.situacao = response;
                    alertProcessandoHide(); 
                    if (this.situacao.status == 'Apto') {
                        this.MeusDadosInscricao(); 
                    } 
                })
                .catch(error => {
                    alertProcessandoHide();
                });
            },
     
            buscarMeusDados() {
                alertProcessando();
                processoSeletivoService.MeusDados()
                .then(response => {
                    this.meusDados = response;
                    alertProcessandoHide();
                })
                .catch(error => {
                    toastErro('Não foi possivel achar o militar na base do RH', error.data.message);
                    alertProcessandoHide();
                });
            },
     
            MeusDadosInscricao() {
                alertProcessando();
                processoSeletivoService.MeusDadosInscricao()
                .then(response => {
                    this.meusDadosInscricao = response;
                    // this.meusDadosInscricao.efetivo.efetivo = 16; //TODO: comentar
                    alertProcessandoHide();
                })
                .catch(error => {
                    alertProcessandoHide();
                });
            },
     
            onSubmit() { 
                if (this.dataIncorporacaoInvalida || this.dataPromocaoInvalida ) { 
                    toastErro('Incorporação ou Promoção invalidos', "");
                    return;
                } 
                if ( this.form.atdp_inscricao_situacao != 1 && this.form.atdp_inscricao_situacao != 0 ) { 
                    toastErro('Favor marcar a situação 1', "");
                    return;
                } 
                if ( this.form.atdp_inscricao_condicao != 1 && this.form.atdp_inscricao_condicao != 0 ) { 
                    toastErro('Favor marcar a situação 2', "");
                    return;
                } 
                if ( this.form.atdp_inscricao_situacao == 1 && ( !this.form.atdp_inscricao_situacao_processo || this.form.atdp_inscricao_situacao_processo == "" || this.form.atdp_inscricao_situacao_processo == " ")  ) { 
                    toastErro('Campos de situação invalidos', "");
                    return;
                }
                this.enviarInscricao();
            },
    
    
            enviarInscricao() { 
                if (this.dataIncorporacaoInvalida || this.dataPromocaoInvalida ||   this.form.atdp_inscricao_situacao == ''   ) {  
                    toastErro('error ao validar', "");
                    return;
                }
                this.confirmaEnvio = false;
                alertProcessando();
                this.form.concurso_cod = this.currentProcessoSeletivo.concurso_cod;
                this.form.concurso_diretriz = this.currentProcessoSeletivo.concurso_diretriz;
                this.form.concurso_dsc = this.currentProcessoSeletivo.concurso_dsc;
                this.form.is_valida_incorporacao = this.dataIncorporacaoInvalida;
                this.form.is_valida_promocao = this.dataPromocaoInvalida;
                this.form.is_valida_saude = this.dataSaudeInvalida;
                this.form.submit('post', processoSeletivoService.getUrl() + '/incricao')
                    .then(response => {
                        toastSucesso(response);
                        alertProcessandoHide();
                        this.VerificarMinhaInscricao(); 
                        window.scrollTo(0, 0);  
                    })
                    .catch(errors => {
                        alertProcessandoHide(); 
                        console.log(errors);
                    });
            }, 
        },
    
    
        computed: {
            ...mapGetters({
                token: 'getToken',
                currentProcessoSeletivo: 'currentProcessoSeletivo',
            }),
    
            dataSaudeInvalida() {
                return moment( this.currentProcessoSeletivo.atdp_inscricao_concurso_data_base ?? "2024-08-31" ).isAfter(this.form.saude_promocao_validade);
            },
     
            dataPromocaoInvalida() {
                if(this.meusDadosInscricao){ 
                    return parseInt( this.meusDadosInscricao.efetivo.post_grad_cod) === parseInt('16') && moment( this.currentProcessoSeletivo.atdp_inscricao_concurso_data_base ??  "2024-08-31").subtract(1, 'years').isBefore(this.form.promocao);
                }
                return  moment( this.currentProcessoSeletivo.atdp_inscricao_concurso_data_base ??  "2024-08-31").subtract(1, 'years').isBefore(this.form.promocao); 
            },
     
            dataIncorporacaoInvalida() { 
                if(this.meusDadosInscricao){
                    return parseInt( this.meusDadosInscricao.efetivo.post_grad_cod) === parseInt('16') && moment( this.currentProcessoSeletivo.atdp_inscricao_concurso_data_base ??  "2024-08-31").subtract(10, 'years').isBefore(this.form.incorporacao);
                }  
                return   moment(this.currentProcessoSeletivo.atdp_inscricao_concurso_data_base ??  "2024-08-31").subtract(10, 'years').isBefore(this.form.incorporacao);
            },
     
        },
     
    }
    </script>
    
    <style lang="css">
    .input-invalid-saude {
        border: 2px red solid;
    }
    
    .input-invalid-promocao {
        border: 2px red solid;
    }
    
    .input-invalid-incorporacao {
        border: 2px red solid;
    }
    
    .invalid-feedback-processo-seletivo {
        width: 100%;
        margin-top: 0.25rem;
        font-size: 0.9rem;
        color: #F64E60;
    }
    </style>