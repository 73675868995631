export const segurancaModel = { 
	getUrlProfile, 
	getUrl, 
	getUrlRelatorio, 
};
  
const url_profile = 'v1';

const url = 'v1/seguranca'  ;

const url_relatorio =  'v1/relatorio' ;

function getUrlProfile(  ) { 
	return  url_profile; 
}

function getUrl(  ) { 
	return  url; 
}

function getUrlRelatorio(  ) { 
	return  url_relatorio; 
}