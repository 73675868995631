<template>   
	<form method="POST" action="#" @submit.prevent="onSubmit" @keydown="form.errors.clear($event.target.name)"> 
		<custom-card :title="title"    :voltar="url_retorno">
            <slot></slot> 
            <template v-slot:title>
                <slot name="title"></slot>
            </template> 
            <template v-slot:footer> 
                <div class="card-footer text-right"> 
                    <slot name="footer"></slot>
                    <btn-salvar></btn-salvar>
                </div>  
            </template> 
        </custom-card>  
	</form>   
</template>
 
<script> 

	export default {

		props:[
		'url' , 'form' , 'metodo' , 'retorno' , 'title'
		], 
          
        data() {
			return {  
				 
            }
		},	
		
		  

  		computed: { 
			url_retorno: function () { 
				if(this.retorno){
					return  this.retorno;
				}
				return '/'; 
			}
		},

		methods: {

			onSubmit() {
				alertProcessando();
				this.form.submit( this.metodo , this.url )
				.then(response => { 
					toastSucesso(response);  
					alertProcessandoHide();
					this.$router.push(  this.url_retorno )
				})
				.catch(errors => { 
					alertProcessandoHide();
					console.log(errors);
				});
			} 
		} 
	} 

</script>
