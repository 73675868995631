<template>  
    <div class="card card-custom gutter-b example example-compact">
		<div class="card-header" v-if="title">
			<div class="card-title">
				<h3 class="card-label" v-html="title"> </h3>
			</div><div class="card-toolbar">
				<div class="example-tools justify-content-center"> 
                    <router-link  :to="voltar" exact  v-if="voltar"  class="btn btn-tool" title="Voltar">
                        <i class="fa fa-reply"></i> 
                    </router-link> 
                    <button title="Voltar" type="button" v-if="back" v-on:click="goBack" class="btn btn-tool">
                        <i class="fa fa-reply"></i> 
                    </button>   
                    <slot name="title"></slot>  
				</div>
			</div>
		</div>
		<div class="card-body"> 
			<slot></slot>
		</div> 

         <slot name="footer"></slot>
	</div>

 
</template>

<script>
    export default {
        props: [
			"color",
			"solid",
			"title",
			"collapse",
			"removable",
			"maximize",
			"voltar",
			"back",
		],

        computed: {

        },

        methods: {
            minimizar() {
                this.min = !this.min;
            },
            expandir() {
                this.exp = !this.exp;
            },
            goBack() {
				window.history.length > 1 ? this.$router.go(-1) : this.$router.push(this.back)
			}
        }, 

        data() {
            return {
                min: true,
                exp: true,
            }
        },
    };

</script>


<style>
    .text-red {
        color: red !important;
    }

</style>
