import Vue from 'vue'
import App from './App.vue'
import router from './router' 
import store from "@/core/services/store";

import axios from 'axios';

window.http_api = axios.create({
  // baseURL: 'https://dev-drh.dcpm.es.gov.br/api/'
//   baseURL: 'http://dev-drh.dcpm.es.gov.br/api/'
  // baseURL: 'localhost:8001/api/'
  // baseURL: 'http://10.200.24.130:8000/api/'
  baseURL: 'https://informatica.pm.es.gov.br/api/'
  // baseURL: 'http://127.0.0.1:8001/api/'
});

  

Vue.config.productionTip = false
 

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/formvalidation";
 

  window.iziToast = require('izitoast');
  window.Swal = require('sweetalert2');

  require('@/assets/js/app'); 
	require('datatables.net');
	require('datatables.net-bs4');
	
	require('datatables.net-responsive');
	require('datatables.net-responsive-bs4');

  

import 'izitoast/dist/css/iziToast.min.css';

import './assets/css/modalProcessamento.css';  
 

//CARD
import Card from './components/_core/adminlte/Card.vue'
Vue.component('custom-card', Card );
 

Vue.component('datatable', require('./components/_core/datatable/datatable.vue').default); 
Vue.component('form-custom', require('./components/_core/formulario/formulario.vue').default);
Vue.component('input-simples', require('./components/_core/formulario/input.vue').default);
Vue.component('campo-custom', require('./components/_core/formulario/campo.vue').default);
Vue.component('card-custom', require('./components/_core/card/card.vue').default);
Vue.component('btn-salvar', require('./components/_core/crud/botaoSalvar.vue').default); 
Vue.component('btn-voltar', require('./components/_core/crud/botaoVoltar.vue').default); 
Vue.component('button-custom', require('./components/_core/botoes/button.vue').default);
Vue.component('crudFormElemento', require('./components/_core/crud/ElementoForm.vue').default);  
  
Vue.component('selectAjax', require('./components/_core/crud/selectAjax.vue').default);
Vue.component('select2', require('./components/_core/crud/SelectComponente.vue').default);  


new Vue({
   
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
