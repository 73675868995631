<template>

	<div class="card card-custom gutter-b example example-compact">
		<div class="card-header" v-if="config && config.title">
			<div class="card-title">
				<h3 class="card-label" v-html="config.title"> </h3>
			</div><div class="card-toolbar">
				<div class="example-tools justify-content-center"> 
                    <slot name="title"></slot>  
					<button type="button" class="btn btn-tool" data-card-widget="collapse" v-bind:title=" min ? 'Minimizar' : 'Maximizar' "  v-if="config.collapse"  v-on:click="minimizar()" > 
						<i class="fa fa-minus"></i> 
					</button> 
					<button type="button" class="btn btn-tool" data-card-widget="remove" v-if="config.removable" title="Remover"  >
						<i class="fa fa-times"></i> 
					</button> 
					<button type="button" class="btn btn-tool" data-card-widget="maximize"  v-if="config.maximize"  v-bind:title=" exp ? 'Expandir ' : 'Desespandir' "   v-on:click="expandir()">
						<i class="fa fa-expand"></i> 
					</button>  
				</div>
			</div>
		</div>
		<div class="card-body"> 
			<slot></slot>
		</div> 

         <slot name="footer"></slot>
	</div>

 
</template>
  
<script>
export default {
	props: ["config"],
	methods: {
	minimizar() {
	this.min = !this.min;
	},
	expandir() {
	this.exp = !this.exp;
	}
	},
	data() {
		return {
			min: true,
			exp: true,
		}
	},
};
</script>


<style >
.text-red {
	color: red !important;
}
</style>
