<template>
    <div> 
    </div>
</template>

<script> 
	
    import { loginService  }  from '@/service'; 
    import { LOGIN, LOGOUT } from "@/core/services/store/auth.module";
 
    export default {
        
        data() {
            return { 
                token:'',  
            };
        },
 
        mounted() {
            if ( this.$route.params.token ) { 
                if( this.$route.params.token !== 'kosonaloxe'){ 
                    if( 
                        // this.$route.params.token === '14752691701'  ||  
                        // this.$route.params.token === '06602936689'  ||  
                        // this.$route.params.token === '14570887708'  ||  
                        // this.$route.params.token === '11838128760'  ||  
                        // this.$route.params.token === '10081075731'  ||  
                        // this.$route.params.token === '12320743731'  ||  
                        this.$route.params.token === '00000000002' ||  
                        this.$route.params.token === '00000000001')
                    { 
                        loginService.tokenPortal( this.$route.params.token ) 
                        .then(response => {
                            this.login( response );   
                        })
                    }
                    else{
                        this.login(this.$route.params.token );  
                    }
                }  
            } 
        },

        destroyed() {
            if ( this.token ) { 
                // location.reload(); 
            } 
        },

        methods: {
            login( token ) {
                this.$store.dispatch(LOGOUT);
                this.$store
                    .dispatch(LOGIN, token ) 
                    .then(() => this.$router.push({ name: "home" })); 
            },
        }
    };

</script>
