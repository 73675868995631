
import * as CryptoJS from 'crypto-js';
const secret_key = 'fgsdf8sfg*bxdfbsd*bxfdsf2323sf**';

 

const ID_TOKEN_KEY = "id_token"; 
 
export const getToken = () => {
  const _stored = window.localStorage.getItem(ID_TOKEN_KEY);
  if (_stored) {
    const bytes  = CryptoJS.AES.decrypt( _stored , secret_key ); 
    const dados = JSON.parse(bytes.toString(CryptoJS.enc.Utf8)); 
    return dados;
  }
  return null; 
};



// export const getHeader = () => { 
//   return { 'Authorization': 'Bearer ' + getToken() }; 
// };


export const saveToken = token => { 
  const _encripted_token = CryptoJS.AES.encrypt(JSON.stringify(token), secret_key ).toString();  
  window.localStorage.setItem(ID_TOKEN_KEY, _encripted_token);
};
 



export const destroyToken = () => {
  
  window.localStorage.removeItem(ID_TOKEN_KEY);

  window.localStorage.removeItem(ID_PERFIL_SELECIONADO_KEY);


};




const ID_PERFIL_SELECIONADO_KEY = "perfil_selecionado"; 
 
export const getPerfilSelecionado = () => {
  const _stored = window.localStorage.getItem(ID_PERFIL_SELECIONADO_KEY);
  if (_stored) {
    const bytes  = CryptoJS.AES.decrypt( _stored , secret_key ); 
    const dados = JSON.parse(bytes.toString(CryptoJS.enc.Utf8)); 
    return dados;
  }
  return {nome:null, unidade:null}; 
};


export const savePerfilSelecionado = perfil => { 
  const _encripted_perfil = CryptoJS.AES.encrypt(JSON.stringify(perfil), secret_key ).toString();  
  window.localStorage.setItem(ID_PERFIL_SELECIONADO_KEY, _encripted_perfil);
};
 
export const destroyPerfilSelecionado = () => {
  window.localStorage.removeItem(ID_PERFIL_SELECIONADO_KEY);
};



  









export default {
  getToken,
  saveToken,
  destroyToken,
  // getHeader

  getPerfilSelecionado,
  savePerfilSelecionado,
  destroyPerfilSelecionado, 
};
