<template>   
	<div class="form-group">
		<slot></slot>
		<p class="text-red" v-if="errors" v-text="errors_texto"></p> 
		<!-- <v-chip   class="ma-2" close color="red" text-color="white" @click:close="chip2 = false" v-if="errors" v-text="errors_texto"> </v-chip> --> 
		<!-- <v-alert dense   type="error" v-text="errors_texto" v-if="errors">  </v-alert> -->
		<!-- <div class="invalid-feedback" v-if="errors" ></div> -->
	</div> 
</template>

<script> 
	export default { 
		props:[
		'errors' , 'errors_texto'
		],  
	} 
</script>

<style scoped> 
</style>