import { segurancaModel } from './index.model';


export const unidadeService = {
	getUnidade,
	getUrl,  
	getTodas,
	getUnidadesMovimentacao,
	// getSubUnidade,  
	// getMilitares,  
	// getTipoLotacaoUnidade,  
	// getMilitaresDaUnidadePai,  
	// buscarHistorico,  
	// getUnidadeParaGerarEscala,  
};

const url = segurancaModel.getUrl() + '/unidade' ;   
 

function getUrl(  ) { 
	return  window.http_api.defaults.baseURL + url; 
}



function getUnidade(id) { 
	return  new Promise((resolve, reject) => {
		window.http_api.get( url + '/' + id    )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			reject(error.response);
		}) 
	}); 
}
  




function getTodas(search) {  
    if(search){
        return  new Promise((resolve, reject) => {
            window.http_api.get( url + '?search=' + search    )
            .then(response => {    
                resolve( response.data);  
            })
            .catch(error => {  
                reject(error.response);
            }) 
        }); 
    } 
    return  new Promise((resolve, reject) => {
		window.http_api.get( url )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			reject(error.response);
		}) 
	}); 
}




function getUnidadesMovimentacao(  ) { 
	return  new Promise((resolve, reject) => {
		window.http_api.get( url +  '/movimentacao/principal'  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			reject(error.response);
		}) 
	}); 
}





// function getSubUnidade( id   ) { 
// 	return  new Promise((resolve, reject) => {
// 		window.http_api.get( url + '/' + id + '/subunidade'  )
// 		.then(response => {    
// 			resolve( response.data);  
// 		})
// 		.catch(error => {  
// 			reject(error.response);
// 		}) 
// 	}); 
// }


// function getMilitaresDaUnidadePai( id   ) { 
// 	return  new Promise((resolve, reject) => {
// 		window.http_api.get( url + '/' + id + '/militares_unidade_pai'   )
// 		.then(response => {    
// 			resolve( response.data);  
// 		})
// 		.catch(error => {  
// 			reject(error.response);
// 		}) 
// 	}); 
// }
  


// function getMilitares( id  ) { 
// 	return  new Promise((resolve, reject) => {
// 		window.http_api.get( url + '/' + id + '/militares'   )
// 		.then(response => {    
// 			resolve( response.data);  
// 		})
// 		.catch(error => {  
// 			reject(error.response);
// 		}) 
// 	}); 
// }
  


// function buscarHistorico( id   ) { 
// 	return  new Promise((resolve, reject) => {
// 		window.http_api.get( url + '/' + id + '/historico'   )
// 		.then(response => {    
// 			resolve( response.data);  
// 		})
// 		.catch(error => {  
// 			reject(error.response);
// 		}) 
// 	}); 
// }
  

// function getTipoLotacaoUnidade(   ) { 
// 	return  new Promise((resolve, reject) => {
// 		window.http_api.get( url + '/tipos/lotacao'    )
// 		.then(response => {    
// 			resolve( response.data);  
// 		})
// 		.catch(error => {  
// 			reject(error.response);
// 		}) 
// 	}); 
// }
  



 