import { segurancaModel } from './index.model'; 
  

export const profileService = { 
	getUrl, 
	MeusDados, 
};
 

const url = segurancaModel.getUrlProfile() + '/profile' ;  
 

function getUrl(  ) { 
	return window.http_api.defaults.baseURL + url; 
}






function MeusDados(  ) { 
	return  new Promise((resolve, reject) => {
		window.http_api.get( url   )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			reject(error.response);
		}) 
	}); 
}



 