export const assentamentoModel = {  
    getUrl,
    getKeyCripto,
    getUrlConsulta,
};
   

const url = 'v1/assentamento'  ;
const url_consulta = 'v1/consulta'  ;
 
const secret_key = 'sky3489**';
 

function getUrl(  ) { 
	return  url; 
}
 

function getUrlConsulta(  ) { 
	return  url_consulta; 
}

function getKeyCripto(  ) { 
	return  secret_key; 
}
 