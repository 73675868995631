import { segurancaModel } from './index.model';

export const userService = {
	 
	logout,
	getUrl,
	getUrlRelatorio,
	getUsuario,
	buscarPerfis, 
	getUrl_loginlog,
	loginToken,
	removerPerfil
};

const url = segurancaModel.getUrl() + '/usuario' ;  
const url_loginlog = segurancaModel.getUrl() + '/loginlog' ;  
const url_relatorio = segurancaModel.getUrlRelatorio() + '/perfil' ; 


function getUrl(  ) { 
	return window.http_api.defaults.baseURL + url; 
}

function getUrl_loginlog(  ) { 
	return  window.http_api.defaults.baseURL + url_loginlog; 
}


function getUrlRelatorio(  ) { 
	return window.http_api.defaults.baseURL + url_relatorio; 
}




function removerPerfil( id  , perfil  ,  unidade    ) { 
	return  new Promise((resolve, reject) => {
		window.http_api.delete( url +'/'+ id + '/perfil/' + perfil + '/unidade/' + unidade   )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			reject(error.response);
		}) 
	}); 
}





function getUsuario( id ) { 
	return  new Promise((resolve, reject) => {
		window.http_api.get( url + '/' + id    )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			reject(error.response);
		}) 
	}); 
}
 




 
function buscarPerfis( id ) { 
	return  new Promise((resolve, reject) => {
		window.http_api.get( url + '/' + id + "/perfil/adicionar"  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			reject(error.response);
		}) 
	}); 
}
 
 

function logout() { 
	localStorage.removeItem('user');
	localStorage.clear( ); 
}


function loginToken(token) { 
	return  new Promise((resolve, reject) => { 
		resolve( token);   
	}); 
}
