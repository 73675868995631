import { segurancaModel } from './index.model'; 
  

export const perfilService = {
	getPerfil,
	getUrl, 
	buscarPermissoes,
	getUrlRelatorio,
	getTodos, 
};



const url = segurancaModel.getUrl() + '/perfil' ; 
const url_relatorio = segurancaModel.getUrlRelatorio() + '/perfil' ; 



function getUrl(  ) { 
	return window.http_api.defaults.baseURL + url; 
}


 
function getUrlRelatorio(  ) { 
	return window.http_api.defaults.baseURL + url_relatorio; 
}



function getPerfil( id ) { 
	return  new Promise((resolve, reject) => {
		window.http_api.get( url + '/' + id  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			reject(error.response);
		}) 
	}); 
}
  

function buscarPermissoes( id ) { 
	return  new Promise((resolve, reject) => {
		window.http_api.get( url + '/' + id + '/permissao/adicionar'   )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			reject(error.response);
		}) 
	}); 
}
  
 

function getTodos(   ) { 
	return  new Promise((resolve, reject) => {
		window.http_api.get( url   )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			reject(error.response);
		}) 
	}); 
}
  
 