import JwtService from "@/core/services/jwt.service";
import { loginService , unidadeService  }  from '@/service'; 
const KJUR = require('jsrsasign'); 

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout"; 
export const PERFIL = "perfil";  
export const PERFIL_RESET = "perfil_reset"; 
// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser"; 
export const SET_ERROR = "setError";
export const SET_PERFIL = "setPerfil";

export const RESET_PERFIL = "resetPerfil";

const state = {
  errors: null,
  user: {},
  token: null,
  perfis: [],
  perfil_selecionado: JwtService.getPerfilSelecionado(), 
  isAuthenticated: !!JwtService.getToken()
};




const getters = {

  currentUser(state) {
    return state.user;
  },

  getHeader(state) {
    return { 'Authorization': 'Bearer ' + state.token }; 
  },

  getPerfis(state) {
    return state.perfis;
  },


  
  getToken(state) {
    return state.token;
  },



  getPerfilSelecionado: state => state.perfil_selecionado ,

   

  isAuthenticated(state) {
    return state.isAuthenticated;
  },


  admin() {
    if ( state.perfil_selecionado ) {
      return state.perfil_selecionado.nome ==="Admin";
    }
    return false; 
  },

  rh1(){
    if ( state.perfil_selecionado ) {
      return state.perfil_selecionado.nome ==="RH_1";
    }
    return false; 
  },


  gimp() {
    if ( state.perfil_selecionado ) {
      return state.perfil_selecionado.nome ==="GIPM";
    }
    return false; 
  },

  cpp() {
    if ( state.perfil_selecionado ) {
      return state.perfil_selecionado.nome ==="RH_8_CPP";
    }
    return false; 
  },

  p1() {
    if ( state.perfil_selecionado ) {
      return state.perfil_selecionado.nome ==="P1_OME";
    }
    return false;
  },

  
  unidade_selecionada() {
    if ( state.perfil_selecionado ) {
      return state.perfil_selecionado.unidade  ;
    }
    return null;
  },



};

const actions = {
  
  
  [LOGIN](context, credentials) {
    return new Promise(resolve => { 
      loginService.login( credentials ) 
        .then(response => {
          context.commit(SET_AUTH, response); 
          resolve(response); 
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.errors);
      });  
    });
  },


  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },


  
  [PERFIL](context , perfil) {
    context.commit(SET_PERFIL , perfil );
  },
  
  [PERFIL_RESET](context  ) {
    context.commit(RESET_PERFIL  );
  },



  [VERIFY_AUTH](context) { 
    const _token = JwtService.getToken(); 
    if (_token) { 
      context.commit(SET_AUTH, _token);  
    } else {
      context.commit(PURGE_AUTH);
    }
  },

};

const mutations = {
  
  
  [SET_ERROR](state, error) {
    state.errors = error;
  },
   
  
  [SET_AUTH](state, token) {
    JwtService.destroyToken();
    state.isAuthenticated = true;
    state.token = token;
    window.http_api.defaults.headers.common["Authorization"] = `Bearer ${token}`; 
    const _payload = KJUR.jws.JWS.parse(token).payloadObj;
    state.user = _payload.user;
    // state.perfis = ;
    state.perfis = [];
    _payload.perfis.forEach(element => {
      unidadeService.getUnidade(element.unidade)
        .then(response => { 
          element.unidade_descricao = response.nome;
          element.unidade_objeto = response ;
          state.perfis.push(element); 
        }); 
    } );


    state.errors = {};
    JwtService.saveToken(token);
  },


  
  [SET_PERFIL](state, perfil) {
    state.perfil_selecionado = perfil;
    JwtService.savePerfilSelecionado(perfil); 
  },

  

  
  [RESET_PERFIL](state ) {
    state.perfil_selecionado = null;
    JwtService.destroyPerfilSelecionado(); 
  },

  
  
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
    // window.location.href = "https://portal.pm.es.gov.br/";
  }
  
};

export default {
  state,
  actions,
  mutations,
  getters
};
