import { assentamentoModel } from './index.model'; 
 

export const militarService = {
	getModel,
	getUrl,  
};


const url = assentamentoModel.getUrl() + '/militar' ;  



function getUrl(  ) { 
	return window.http_api.defaults.baseURL + url; 
}



function getModel( id  ) { 
	return  new Promise((resolve, reject) => {
		window.http_api.get( url + '/' + id    )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			reject(error.response);
		}) 
	}); 
}
  