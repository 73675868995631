<template>
    <div class="home"> 
        <v-card class="mx-auto" max-width="744" outlined  v-for="curso in cursos_abertos" :key="curso.id" > 
            <br>
            <br>
            <div class="row">
                <div class="col-12">
                    <div class=" text-center"> 
                        <h2>PROCESSO SELETIVO {{curso.concurso_dsc.replace('_' , ' ')}} </h2>  
                    </div>
                </div>
            </div>  
            <v-list-item>
                <v-list-item-content max-width="744"> 
                    <h3>Atenção para os avisos:</h3>
                    <br><br>
                    <h4>{{ curso.concurso_diretriz }}</h4> 
                    <br><br>  
                    <h4>Periodo de inscrição de <strong>{{ curso.concurso_ini | format_data }}</strong> à <strong class="data-final">{{ curso.concurso_fim | format_data }}</strong></h4>   
                </v-list-item-content>  
            </v-list-item>   
            <br><br>
            <div class="row manza-row">
                <div class="col-12 text-center"> 
                    <v-btn outlined raised rounded  class="manza-btn"  @click="setProcessoSeletivo(curso)"   color="primary" elevation="10" >
                        <h1>INSCRIÇÃO</h1> 
                    </v-btn>   
                </div> 
            </div> 
            <div class="card-footer  ">   
                <v-card-text  class=" text-danger6"  >
                    Qualquer problema TÉCNICO apresentado pelo Portal da PMES, no ato da inscrição, deverá ser solucionado junto a DTIC, através do telefone 3636-8888 (suporte) no horário administrativo.
                </v-card-text>
            </div>
        </v-card>  



        <v-card class="mx-auto" max-width="744" outlined  v-if="cursos_abertos.length < 1 "  style="margin-bottom:20px;">
            <v-list-item three-line>
                <v-list-item-content>
                    <div class=" text-caption text-center"> 
                        <h4>NENHUM PROCESSO SELETIVO DISPONÍVEL </h4>  
                    </div> 
                </v-list-item-content> 
                <v-list-item-avatar tile size="80" color="grey" ></v-list-item-avatar>
            </v-list-item>  
            <v-card-actions class="d-flex justify-center mb-6"> </v-card-actions>
        </v-card>  



    </div>
</template>

<script>
 
    import { PROCESSO_SELETIVO } from "@/core/services/store/processo.seletivo.module";
    import { processoSeletivoService } from "@/service"; 
    import moment from "moment";

    export default {
        
        name: "Home",
          
        data() {
            return { 
                cursos_abertos:'', 
            };
        },
  
        created() {
            this.VerificarProcessoSeletivoAberto(); 
        }, 

        methods: {
             
            setProcessoSeletivo(proc){ 
                this.$store.dispatch( PROCESSO_SELETIVO , proc )
                .then( () =>{ 
                    this.$router.push('/profile/inscricao');
                }) ; 
            },
 
            VerificarProcessoSeletivoAberto() {
                alertProcessando();
                processoSeletivoService.VerificarProcessoSeletivoAberto()
                .then((response) => {
                    this.cursos_abertos = response;
                    alertProcessandoHide();
                })
                .catch((error) => { 
                    alertProcessandoHide();
                });
            }, 
        }, 

        filters: {
            format_data: function (value) { 
                if (!value) return '' 
                let date = moment( value );  
                return date.format('DD/MM/YYYY');  
            },
        }
 
    };
</script>
  
<style >
    .data-final{
        color:red; 
    }

    .manza-btn{
        height: 55px !important;
    }
    h4{
        margin-top: 20px;
    }

    .manza-row{
        margin-bottom: 20px !important;
    }
</style>

 