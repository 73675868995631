import Vue from "vue";
import Router from "vue-router";
import JwtService from "@/core/services/jwt.service";
const KJUR = require('jsrsasign'); 


import Login from '@/view/paginas/auth/Login.vue';
import Home from '@/view/Principal.vue'; 
import Erro6 from '@/view/paginas/errors/Error-6.vue'; 
import Erro4 from '@/view/paginas/errors/Error-4.vue'; 
  
import Relatorio from '@/view/paginas/profile/Index.vue';   
 
// import Profile_Inscricao_Processo_Seletivo from '@/view/paginas/processoSeletivo/chs2021/Index.vue';
import Profile_Inscricao_Processo_Seletivo2022 from '@/view/paginas/processoSeletivo/chs2022/Index.vue';
 
Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: "/",
      redirect: "/home",
      component: () => import("@/view/layout/Layout"), 
      children: [ 
        {
          path: "/home",
          name: "home",
          component: Home,
          meta: { requiresAuth: true  },
        },  
        {
          path: "/profile",
          name: "profile",
          component: Relatorio, 
          children: [  
            {
              path: "inscricao",
              name: "profile_inscricao_processo_seletivo",
              component: Profile_Inscricao_Processo_Seletivo2022,
              // component: Profile_Inscricao_Processo_Seletivo,
              meta: { requiresAuth: true  },
            }, 
          ] 
        }, 
      ]
    }, 
    {
      name: "logintoken",
      path: '/login/token/:token',
      component: Login,
    }, 
    {
      path: "*",
      redirect: "/404"
    }, 
    { 
      path: "/404",
      name: "404",
      component: Erro4
    }, 
    { 
      path: "/acesso_restrito",
      name: "406",
      component: Erro6
    } 
  ]
});
  
// http://10.200.24.130:8080/#/login/token/00000000001 
// http://10.200.24.138:8083/#/login/token/00000000002 
// https://informatica.pm.es.gov.br/#/login/token/00000000001

router.beforeEach((to, from, next) => {
	if (to.matched.some(record => record.meta.requiresAuth)) {
		var user = JwtService.getToken();
		
		if (!user) {
      window.location.href = "https://portal.pm.es.gov.br/"
      // next({  
      //   path: '/login',
      //   query: {
			// 		redirect: to.fullPath,
			// 	},
			// });
		}
		else if (to.matched.some(record => record.meta.requiresPerfil)) {  
      var perfil_selecionado = JwtService.getPerfilSelecionado(); 
      var temp = false;  
      temp = to.meta.requiresPerfil.filter((element) => { 
        if (perfil_selecionado ) {
          if (perfil_selecionado.nome == element) {
            return  true;  
          } 
        }
        return false;
      }).length > 0;
      
			if(temp){ 
        next();
      }
			else { 
				next({
					path: '/acesso_restrito',  
					query: {
						// redirect: to.fullPath,
					},
				});
			}
		}
		else {
			next();
		}  
	} else {
		next();
	}
})


export default router