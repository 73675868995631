import { segurancaModel } from './index.model'; 
 

export const acessoService = {
	 
	getUrl,  
};


const url = segurancaModel.getUrl() + '/acesso' ;  



function getUrl(  ) { 
	return window.http_api.defaults.baseURL + url; 
}


 
  