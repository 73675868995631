
// action types
export const PROCESSO_SELETIVO = "processo_seletivo";
export const PROCESSO_SELETIVO_RESET = "processo_seletivo_reset"; 
  

// mutation types 
export const SET_PROCESSO_SELETIVO = "setProcessoSeletivo";  
export const RESET_PROCESSO_SELETIVO = "resetProcessoSeletivo";



const state = {
  processo: null, 
};

 
const getters = { 
  currentProcessoSeletivo(state) {
    return state.processo;
  }, 
};

const actions = {
   
  
  [PROCESSO_SELETIVO](context , proc) {
    context.commit( SET_PROCESSO_SELETIVO , proc );
  },
  
  [PROCESSO_SELETIVO_RESET]( context  ) {
    context.commit( RESET_PROCESSO_SELETIVO  );
  },
 

};

const mutations = {
  
    
  
  [SET_PROCESSO_SELETIVO](state, proc) {
    state.processo = proc; 
  },

  

  
  [RESET_PROCESSO_SELETIVO](state ) {
    state.processo = null; 
  },

   
  
};

export default {
  state,
  actions,
  mutations,
  getters
};
