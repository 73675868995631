<template>  
	<select ref="input"  :id="id">
		<slot></slot>
	</select>
</template>

<script>

	import jQuery  from 'jquery';   
	import select2 from 'select2';
	import 'select2/dist/css/select2.css'; 
	// import 'bootstrap/dist/css/bootstrap.css';
	!function(){if(jQuery&&jQuery.fn&&jQuery.fn.select2&&jQuery.fn.select2.amd)var e=jQuery.fn.select2.amd;e.define("select2/i18n/pt-BR",[],function(){return{errorLoading:function(){return"Os resultados não puderam ser carregados."},inputTooLong:function(e){var n=e.input.length-e.maximum,r="Apague "+n+" caracter";return 1!=n&&(r+="es"),r},inputTooShort:function(e){return"Digite "+(e.minimum-e.input.length)+" ou mais caracteres"},loadingMore:function(){return"Carregando mais resultados…"},maximumSelected:function(e){var n="Você só pode selecionar "+e.maximum+" ite";return 1==e.maximum?n+="m":n+="ns",n},noResults:function(){return"Nenhum resultado encontrado"},searching:function(){return"Buscando…"},removeAllItems:function(){return"Remover todos os itens"}}}),e.define,e.require}();

	export default {

		props:[
		'options' , 'value'  , 'propriedade' , 'id' , 'errors' , 'errors_texto'
		],

		mounted: function () {
			
			var vm = this

			for (var prop in this.propriedade) { 
            	this.$refs.input.setAttribute(  prop  , this.propriedade[prop]  ); 
			}

			jQuery(this.$el) 
			.select2({ language:'pt-BR', data: this.options  })  
			.val(this.value)
			.trigger('change') 
			.on('change', function () {  
				vm.$emit('input', this.value)
			})
 
		},

		watch: {

			value: function (value) {  
				jQuery(this.$el)  
				.val(value)
				.trigger('change')

				this.$emit('input-select-alterado', this.value)
			},

			options: function (options) { 
				jQuery(this.$el).empty().select2({ data: options }) 
			}, 

			errors: function (errors) { 
				 if(errors){
					 jQuery( "#" + this.id + " + span").addClass("is-invalid");
				 } 
				 else{
					jQuery( "#" + this.id + " + span").removeClass("is-invalid");
				 }
			}

		}, 
		destroyed: function () {
			jQuery(this.$el).off().select2('destroy')
		}
	}

</script>


<style >
 
.select2-container { 
    width: 100% !important;
}

.is-invalid .select2-selection,
.needs-validation ~ span > .select2-dropdown{
  border-color:red !important;
}



.select2-selection {
	height: 40px  !important; 
}

.select2-container--default .select2-selection--single .select2-selection__rendered { 
    line-height: 25px !important;
}

.select2-selection__arrow{
	margin-top: 6px !important;
}
 
</style>
